<template>
  <HList class="options" :items="gameState.options">
    <template v-slot:default="{ item: el, i }">
      <tile
        class="option"
        @click="selectImage(i, $event.target)"
        :image="el.imgSrc"
        :disabled="!canGuess"
      >
        <Label
          class="choice"
          v-if="gameState.guesses[i]"
          :selected="gameState.guesses[i] === gameState.selected"
          :canRemove="canGuess"
          @remove="unguess(i)"
        >
          {{ gameState.guesses[i] }}
        </Label>

        <template v-slot:footer v-if="gameState.hasFlipped == true">
          <template v-if="gameState.guesses[i] == el.name">
            <div class="guess-correct">Correct</div>
          </template>
          <template v-else>
            <div class="guess-incorrect">Incorrect</div>
          </template>

          {{ el.name }}
        </template>
      </tile>
    </template>
  </HList>

  <div class="controls">
    <template v-if="canFlip">
      <div class="instructions">subit your answers when ready</div>
      <div class="choices">
        <btn @click="flip">I'm finished</btn>
      </div>
    </template>
    <template v-else-if="canGuess">
      <div class="instructions">match an artist's name with their picture</div>
      <div class="choices">
        <transition-group name="choice">
          <Label
            class="choice"
            :selected="name === gameState.selected"
            @click="selectName(name)"
            v-for="name in gameState.names"
            :key="name"
          >
            {{ name }}
          </Label>
        </transition-group>
      </div>
    </template>
    <div class="kill-screen" v-else>
      <judgement :maxScore="maxScore" :score="score" />

      <div class="next-steps">
        <btn @click="replay">replay</btn>
        <btn @click="$emit('done')">go back</btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Label from "@/components/Label.vue";
import Tile from "@/components/Tile.vue";
import HList from "@/components/HList.vue";
import { createGame, GameConfig } from "@/gameplay";
import Judgement from "./Judgement.vue";
import Btn from "./Btn.vue";

export default defineComponent({
  name: "Game",

  components: { Label, Tile, Judgement, Btn, HList },

  props: {
    config: {
      required: true,
      type: Object as PropType<GameConfig>,
    },
  },

  setup(props) {
    const game = createGame(props.config);

    return {
      ...game,
    };
  },
});
</script>

<style lang="scss">
.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;

  max-width: var(--max-page-width);
}

.instructions {
  font-weight: 600;
  font-size: large;
  text-align: center;

  margin: 16px 0;
}

.options {
  margin: 0 0 16px;
}

.option {
  margin: 8px;
}

.choice {
  margin: 8px;
}

.choices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 16px 0 32px;

  .choice {
    margin: 4px 8px;
  }

  .choice-move {
    transition: transform 0.1s;
  }

  .choice-enter-active {
    opacity: 0;
    transition: opacity 0.3s;
  }

  .choice-enter-to {
    opacity: 1;
  }
}

.guess-correct,
.guess-incorrect {
  font-size: large;
  font-weight: 600;
}

.guess-correct {
  color: #199b45;
}

.guess-incorrect {
  color: #9b1919;
}

.kill-screen {
  display: grid;
  place-items: center;
}
</style>
