<template>
  <div
    class="tile"
    :class="[{ solid, selectable: !disabled }]"
    :style="{ padding: `${padding}px`, borderRadius: `${radius}px` }"
  >
    <div class="image" :style="{ borderRadius: `${imgRadius}px` }">
      <img
        :src="image"
        :style="{ maxWidth: `${size}px`, maxHeight: `${size}px` }"
      />
      <div class="cell">
        <slot></slot>
      </div>
    </div>
    <div class="caption">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    image: {
      required: true,
      type: String,
    },
    size: {
      default: 250,
      type: Number,
    },
    padding: {
      default: 0,
      type: Number,
    },
    radius: {
      default: 22,
      type: Number,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    solid: {
      default: false,
      type: Boolean,
    },
  },

  setup(props) {
    return {
      imgRadius: computed(() => props.radius - props.padding),
    };
  },
});
</script>

<style lang="scss" scoped>
.tile {
  &.selectable {
    cursor: pointer;
  }
  &.solid {
    background: #ffffff;
    border: 1px solid #84848426;
  }
}
.image {
  box-shadow: 0px 0px 16px #f0e0e7;
  overflow: hidden;
  position: relative;
  border: 1px solid #84848426;

  img {
    display: block;
    width: auto;
    height: auto;
  }

  .cell {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.caption {
  margin-top: 8px;
}
</style>
