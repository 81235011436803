<template>
  <div class="list-container">
    <div class="list">
      <div class="item" v-for="(item, i) in items" :key="i">
        <slot :item="item" :i="i"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "@vue/runtime-core";
export default {
  props: {
    items: {
      required: true,
      type: Object as PropType<[]>,
    },
  },
};
</script>

<style lang="scss" scoped>
.list-container {
  width: 100%;
  overflow: scroll;
  scroll-snap-type: x;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  display: grid;
  place-items: center;
}

.list {
  display: flex;

  .item {
    scroll-snap-align: center;
  }
}
</style>
