
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
  props: {
    items: {
      required: true,
      type: Object as PropType<[]>,
    },
  },
});
