import { GameConfig } from "./gameplay";

export const ObscureArtists: GameConfig = {
  name: "Obscure Artists",
  imgSrc: require("./assets/A-6595305-1531920049-5882.jpeg.jpg"),
  options: [
    {
      imgSrc: require("./assets/A-1529822-1617541915-3955.jpeg.jpg"),
      name: "Attack Attack!",
      bio: "American electronicore band from Westerville, OH, USA, formed 2007. The band broke up in 2013, only to reunite in 2020.",
    },
    {
      imgSrc: require("./assets/A-3029058-1573230408-9702.jpeg.jpg"),
      name: "Panos Karampalasis",
    },
    {
      imgSrc: require("./assets/A-3674203-1461621757-8146.jpeg.jpg"),
      name: "Mayu Kishima",
    },
    {
      imgSrc: require("./assets/A-5227566-1599254471-1899.jpeg.jpg"),
      name: "Shirazee",
    },
    {
      imgSrc: require("./assets/A-6595305-1531920049-5882.jpeg.jpg"),
      name: "FreshtillDef",
      bio: "Footwork / Juke / Jungle producer from the San Antonio, Texas. Co-owner of Freshmoon Records",
    },
  ],
};

export const GameOfTheMonth: GameConfig = {
  name: "Game of the Month",
  imgSrc: require("./assets/A-1186378-1459856693-3656.jpeg.jpg"),
  options: [
    {
      imgSrc: require("./assets/A-1817249-1579502504-2529.jpeg.jpg"),
      name: "January Thompson",
    },
    {
      imgSrc: require("./assets/A-31836-1127764513.jpeg.jpg"),
      name: "April March",
      bio: "April March (April 20, 1965, California, USA) is an American singer and songwriter who sings in English and French.",
    },
    {
      imgSrc: require("./assets/A-835496-1614134473-6649.jpeg.jpg"),
      name: "Mayday Parade",
      bio: "American rock band from Tallahassee, Florida, that was founded in 2005.",
    },
    {
      imgSrc: require("./assets/A-274826-1293673804.jpeg.jpg"),
      name: "June Millington",
      bio: "Filipino American guitarist songwriter and producer, born 14 April 1948 in Manila, Philippines.",
    },
    {
      imgSrc: require("./assets/A-5831540-1529686476-4241.jpeg.jpg"),
      name: "July Eden",
      bio: "Producer from London, UK.",
    },
    {
      imgSrc: require("./assets/A-1186378-1459856693-3656.jpeg.jpg"),
      name: "Jan August",
      bio: "Jan August (born Jan Auggustoff, on September 24, 1904 in New York City, New York, USA - died on January 9, 1976 in New York City) was an American pianist and xylophonist.",
    },
    {
      imgSrc: require("./assets/A-5601717-1609250931-5032.jpeg.jpg"),
      name: "September Stories",
      bio: "A band from Findlay, Ohio who perform Alternative and Spoken Word music.",
    },
    {
      imgSrc: require("./assets/A-2137877-1417968489-5854.jpeg.jpg"),
      name: "October Light",
    },
    {
      imgSrc: require("./assets/A-6179298-1513026522-8174.jpeg.jpg"),
      name: "November Might Be Fine",
      bio: "Silesian band founded by ex-members of Heath.",
    },
    {
      imgSrc: require("./assets/A-4085016-1512900397-9128.jpeg.jpg"),
      name: "December",
      bio: "Paris-based techno producer Tomas More.",
    },
  ],
};

export default [ObscureArtists, GameOfTheMonth];
