<template>
  <div class="item-list">
    <div class="item" v-for="(item, i) in items" :key="i">
      <slot :item="item"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
  props: {
    items: {
      required: true,
      type: Object as PropType<[]>,
    },
  },
});
</script>

<style lang="scss" scoped>
.item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .item {
    margin: 8px;
  }
}
</style>
