
import { defineComponent, PropType } from "vue";
import Label from "@/components/Label.vue";
import Tile from "@/components/Tile.vue";
import HList from "@/components/HList.vue";
import { createGame, GameConfig } from "@/gameplay";
import Judgement from "./Judgement.vue";
import Btn from "./Btn.vue";

export default defineComponent({
  name: "Game",

  components: { Label, Tile, Judgement, Btn, HList },

  props: {
    config: {
      required: true,
      type: Object as PropType<GameConfig>,
    },
  },

  setup(props) {
    const game = createGame(props.config);

    return {
      ...game,
    };
  },
});
