import { shuffle } from "@/util";
import { computed, ComputedRef, reactive } from "vue";

type Guess = string | undefined;
type Guesses = Guess[];

type Choice = {
  imgSrc: string;
  name: string;
  bio?: string;
};

export type GameOptions = Choice[];
export type GameConfig = {
  name: string;
  imgSrc: string;
  options: GameOptions;
};

type GameState = {
  name: string;
  options: GameOptions;
  hasFlipped: boolean;
  names: string[];
  guesses: Guess[];
  selected: Guess;
};

interface Game {
  gameState: GameState;
  canGuess: ComputedRef<boolean>;
  canFlip: ComputedRef<boolean>;
  score: ComputedRef<number>;
  maxScore: ComputedRef<number>;
  replay: () => void;
  flip: () => void;
  selectName: (name?: Guess) => void;
  selectImage: (pos: number, $el?: Element) => void;
  unguess: (pos: number) => void;
}

function _canGuess(gameState: GameState) {
  return !gameState.hasFlipped;
}

function _canFlip(gameState: GameState) {
  return gameState.names.length === 0 && !gameState.hasFlipped;
}

function calcScore(guesses: Guesses, options: GameOptions) {
  return guesses.filter((guess, i) => guess == options[i].name).length;
}

function calcMaxScore(options: GameOptions): number {
  return options.length;
}

const getState = (config: GameConfig) => ({
  name: config.name,
  hasFlipped: false,
  options: shuffle(config.options),
  names: shuffle(config.options.map((el) => el.name)),
  guesses: config.options.map(() => undefined),
  selected: undefined,
});

export function createGame(config: GameConfig): Game {
  const gameState = reactive<GameState>(getState(config));

  const canGuess = computed(() => _canGuess(gameState));
  const canFlip = computed(() => _canFlip(gameState));
  const score = computed(() => calcScore(gameState.guesses, gameState.options));
  const maxScore = computed(() => calcMaxScore(gameState.options));

  const replay = () => Object.assign(gameState, getState(config));
  const flip = () => (gameState.hasFlipped = true);
  const selectName = (name?: Guess) => {
    if (!canGuess.value) return;
    if (gameState.selected !== name) {
      gameState.selected = name;
    } else {
      gameState.selected = undefined;
    }
  };

  const selectImage = (pos: number, $el?: Element) => {
    if ($el) {
      $el.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "end",
      });
    }
    if (!canGuess.value) return;
    if (gameState.selected) {
      // if the selected name is on a guess, clear the guess
      const selected = gameState.selected;
      const prevGuess = gameState.guesses.indexOf(selected);
      const prevGuessName = gameState.guesses[prevGuess];
      if (prevGuessName) {
        gameState.names.push(prevGuessName);
        gameState.guesses[prevGuess] = undefined;
      }

      changeGuess(pos, selected);

      // remove guess from names list
      gameState.names.splice(gameState.names.indexOf(selected), 1);
      selectName();
    } else {
      selectName(gameState.guesses[pos]);
    }
  };

  const changeGuess = (pos: number, guess: Guess = undefined) => {
    const nextGuess = gameState.guesses[pos];
    if (nextGuess != undefined) {
      gameState.names.push(nextGuess);
    }

    gameState.guesses[pos] = guess;
  };

  const unguess = (pos: number) => {
    if (!canGuess.value) return;
    changeGuess(pos);

    gameState.selected = undefined;
  };

  return {
    replay,
    gameState,
    canGuess,
    flip,
    score,
    maxScore,
    selectName,
    selectImage,
    unguess,
    canFlip,
  };
}
