
import { defineComponent, ref } from "vue";
import Game from "@/components/Game.vue";
import FlexList from "@/components/FlexList.vue";
import Tile from "@/components/Tile.vue";
import { GameConfig } from "@/gameplay";
import games from "@/games";

export default defineComponent({
  name: "App",

  components: { Game, FlexList, Tile },

  setup() {
    const gameConfig = ref<GameConfig>();

    return {
      gameConfig,
      games,
    };
  },
});
