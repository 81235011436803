<template>
  <header class="soitbegins">
    <div class="container">
      <h1 class="site-name" @click.prevent="gameConfig = undefined">
        <span class="b">boredom</span><br /><span class="m">monkey</span>
      </h1>
      <div v-if="gameConfig">/ {{ gameConfig.name }}</div>
    </div>
  </header>

  <game :config="gameConfig" @done="gameConfig = undefined" v-if="gameConfig" />
  <template v-else>
    <h2>Select a game</h2>
    <flex-list :items="games">
      <template v-slot:default="{ item: game }">
        <tile
          class="game-tile"
          :image="game.imgSrc"
          :size="330"
          :padding="8"
          solid
          @click="() => (gameConfig = game)"
        >
          <template v-slot:footer>
            <div class="game-title">
              {{ game.name }}
            </div>
          </template>
        </tile>
      </template>
    </flex-list>
  </template>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Game from "@/components/Game.vue";
import FlexList from "@/components/FlexList.vue";
import Tile from "@/components/Tile.vue";
import { GameConfig } from "@/gameplay";
import games from "@/games";

export default defineComponent({
  name: "App",

  components: { Game, FlexList, Tile },

  setup() {
    const gameConfig = ref<GameConfig>();

    return {
      gameConfig,
      games,
    };
  },
});
</script>

<style lang="scss">
:root {
  --c9: #ffffff;
  --c2: #f9f3f3;
  --c0: #2a2a2e;
  --c5: #ee4c7c;
  --c4: #9a1750;
  --max-page-width: 920px;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  background: var(--c2);
  color: var(--c0);
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // always be sure to have space on the bottom
  padding-bottom: 32px;
}

.soitbegins {
  display: grid;
  place-items: center;
  background-color: var(--c9);
  margin-bottom: 32px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;

  border-bottom: 1px solid #84848426;

  h1 {
    margin: 1rem 1rem;
    cursor: pointer;
  }

  .container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: var(--max-page-width);
  }
}

h2 {
  margin-top: 8px;
}

.site-name {
  line-height: 0.8;
}

.game-title {
  text-align: center;
  font-size: large;
}
</style>
