
import { selectRandom } from "@/util";
import { computed, defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    score: {
      required: true,
      type: Number,
    },
    maxScore: {
      required: true,
      type: Number,
    },
  },

  setup(props) {
    const perfect = [
      {
        title: "Perfect score",
        caption: "there is nothing more I can teach you",
      },
      { title: "Perfect score", caption: "now, try again without googling" },
      { title: "Perfect score", caption: "power up complete" },
      { title: "💯 ✨", caption: "you're the best" },
    ];
    const imperfect = [
      { title: "You missed a few", caption: "maybe next time" },
      { title: "You missed a few", caption: "study. cram. replay." },
      { title: "You missed a few", caption: "try again and shoot for perfect" },
    ];
    const horrifying = [
      { title: "No.", caption: "maybe you lost on purpose?" },
      { title: "Ummm", caption: "there are no words" },
      { title: "Zero. You scored Zero.", caption: "Click that replay button" },
      { title: "Yikes", caption: "Click that replay button" },
    ];

    const message = computed(() => {
      if (props.score == props.maxScore) {
        return selectRandom(perfect);
      } else if (props.score > 0) {
        return selectRandom(imperfect);
      } else {
        return selectRandom(horrifying);
      }
    });

    return {
      message,
    };
  },
});
