<template>
  <div :class="['label', { selected }]">
    <span class="name"><slot /></span>
    <div class="remove-selection" @click="$emit('remove')" v-if="canRemove">
      &#xd7;
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    selected: {
      default: false,
      type: Boolean,
    },

    canRemove: {
      default: false,
      type: Boolean,
    },
  },
});
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--c9);
  color: var(--c0);
  border-radius: 99999px;
  cursor: pointer;
  border: 1px solid #84848426;
  white-space: nowrap;

  &.selected {
    background: var(--c5);
    color: var(--c9);
  }

  .name {
    padding: 6px 12px;
  }

  .remove-selection {
    display: grid;
    place-items: center;
    border-radius: 99999px;
    padding: 0 12px 2px;
  }
}
</style>
