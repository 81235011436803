<template>
  <button class="btn">
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped>
.btn {
  border: 0;
  background: var(--c9);
  border-radius: 8px;
  font-size: large;
  color: var(--c0);
  padding: 8px 24px;
  margin: 8px;
  cursor: pointer;

  border: 1px solid #84848426;
}
</style>
