
import { PropType } from "@vue/runtime-core";
export default {
  props: {
    items: {
      required: true,
      type: Object as PropType<[]>,
    },
  },
};
